import React from "react"

const BlurryPattern = () => {
  return (
    <div
      className="absolute left-[calc(50%-4rem)] top-10 -z-10 transform-gpu blur-3xl sm:left-[calc(50%-18rem)] lg:left-48 lg:top-[calc(50%-30rem)] xl:left-[calc(50%-24rem)]"
      aria-hidden="true"
    >
      <div
        className="aspect-[1108/632] w-[69.25rem] bg-primary opacity-50"
        style={{
          clipPath:
            "polygon(73.6% 51.7%, 94.7% 19.72%, 100% 46.4%, 97.4% 82.2%, 79.32% 85.51%, 75.7% 64%, 64.22% 65.93%, 53.45% 57.61%, 52.74% 67.86%, 50.3% 87.2%, 21.82% 80.34%, 5.05% 85.51%, 5.05% 42.69%, 20.09% 51.36%, 29.5% 25.78%, 56.08% 19.72%, 73.6% 51.7%)",
        }}
      />
    </div>
  )
}

export default BlurryPattern
