import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const stats = [
  { id: 1, name: "Platzierung im App Store (Kostenlos)", value: "# 2" },
  { id: 2, name: "App Downloads", value: "180.000+" },
  { id: 3, name: "Registrierte Benutzer", value: "139.000+" },
  { id: 4, name: "Monatliche Seitenbesuche", value: "1,2+ Mio" },
  { id: 5, name: "Durchschnittliche Bewertung", value: "4,7 ⭐" },
  { id: 6, name: "Anzahl Bewertungen", value: "~1,6 k" },
]

const Pbcp = () => {
  return (
    <div className="relative ">
      <div className="mx-auto grid max-w-7xl lg:grid-cols-2">
        <StaticImage
          src="../images/pbcp_mockup.png"
          alt="PBCP App"
          className="w-full object-cover rounded-3xl"
        />
        <div className="px-6 pb-24 pt-16 sm:pb-32 sm:pt-20 lg:col-start-2 lg:px-8 lg:pt-32">
          <div className="mx-auto max-w-2xl lg:mr-0 lg:max-w-lg">
            <p className="text-base font-semibold leading-8 text-primary">
              iOS, Web & Backend
            </p>
            <h2 className="mt-2 text-3xl font-bold tracking-tight sm:text-4xl">
              PBCP App
            </h2>
            <p className="mt-6 text-lg leading-8 text-gray-300">
              Mit der PBCP iOS App haben wir eine Full-Stack-Lösung für den
              Betreiber des Dienstes erstellt und technisch betreut. Wir konnten
              Herausforderungen, wie eine nahtlose Migration zu unserer Lösung,
              Lastspitzen bei viralen Social Media Posts und Fernsehbeiträgen
              sowie eine exponentiell wachsende Benutzerbasis unter einem
              überdurchschnittlich wirtschaftlichen TCO meistern.
            </p>
            <p className="mt-10 text-sm text-gray-300 font-light">
              Hinweis: Der Betreiber hat den Dienst aus privaten Gründen
              eingestellt.
            </p>
            <dl className="mt-16 grid max-w-xl grid-cols-1 gap-8 sm:mt-20 sm:grid-cols-2 xl:mt-16">
              {stats.map(stat => (
                <div
                  key={stat.id}
                  className="flex flex-col gap-y-3 border-l border-gray-100/10 pl-6"
                >
                  <dt className="text-sm leading-6 text-gray-300">
                    {stat.name}
                  </dt>
                  <dd className="order-first text-3xl font-semibold tracking-tight text-primary">
                    {stat.value}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Pbcp
