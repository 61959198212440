import React, { useEffect, useRef, useState } from "react"
import clsx from "clsx"
import Container from "./container"

import reviews from "../../static/reviews.json"

const StarIcon = props => {
  return (
    <svg viewBox="0 0 20 20" aria-hidden="true" {...props}>
      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
    </svg>
  )
}

const StarRating = ({ rating }) => {
  return (
    <div className="flex">
      {[...Array(5).keys()].map(index => (
        <StarIcon
          key={index}
          className={clsx(
            "h-5 w-5",
            rating > index ? "fill-yellow-500" : "fill-gray-300"
          )}
        />
      ))}
    </div>
  )
}

const Review = ({ title, review, nickname, rating, className, ...props }) => {
  return (
    <figure
      className={clsx(
        "rounded-3xl bg-white/5 ring-1 ring-white/10 p-6  shadow-md shadow-gray-900/5",
        className
      )}
      {...props}
    >
      <blockquote className="text-gray-300">
        <StarRating rating={rating} />
        <p className="mt-4 text-lg font-semibold leading-6 before:content-['“'] after:content-['”']">
          {title}
        </p>
        <p className="mt-3 text-base leading-7">{review}</p>
      </blockquote>
    </figure>
  )
}

const splitArray = (array, numParts) => {
  let result = []
  for (let i = 0; i < array.length; i++) {
    let index = i % numParts
    if (!result[index]) {
      result[index] = []
    }
    result[index].push(array[i])
  }
  return result
}

const ReviewColumn = ({
  reviews,
  className,
  reviewClassName,
  msPerPixel = 0,
}) => {
  let columnRef = useRef(null)
  let [columnHeight, setColumnHeight] = useState(0)
  let duration = `${columnHeight * msPerPixel}ms`

  useEffect(() => {
    if (!columnRef.current) {
      return
    }

    let resizeObserver = new window.ResizeObserver(() => {
      setColumnHeight(columnRef.current?.offsetHeight ?? 0)
    })

    resizeObserver.observe(columnRef.current)

    return () => {
      resizeObserver.disconnect()
    }
  }, [])

  return (
    <div
      ref={columnRef}
      className={clsx("animate-marquee space-y-8 py-4", className)}
      style={{ "--marquee-duration": duration }}
    >
      {reviews.concat(reviews).map((review, reviewIndex) => (
        <Review
          key={reviewIndex}
          aria-hidden={reviewIndex >= reviews.length}
          className={reviewClassName?.(reviewIndex % reviews.length)}
          {...review.value}
        />
      ))}
    </div>
  )
}

const ReviewGrid = () => {
  let columns = splitArray(reviews, 3)
  let column1 = columns[0]
  let column2 = columns[1]
  let column3 = splitArray(columns[2], 2)

  return (
    <div className="relative -mx-4 grid h-[49rem] max-h-[150vh] grid-cols-1 items-start gap-8 overflow-hidden px-4 md:grid-cols-2 lg:grid-cols-3">
      <div
        className="absolute inset-40 -top-16 -z-10 flex transform-gpu justify-center overflow-hidden blur-3xl"
        aria-hidden="true"
      >
        <div
          className="aspect-[1318/752] w-[82.375rem] flex-none bg-primary opacity-25"
          style={{
            clipPath:
              "polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)",
          }}
        />
      </div>
      <>
        <ReviewColumn
          reviews={[...column1, ...column3.flat(), ...column2]}
          reviewClassName={reviewIndex =>
            clsx(
              reviewIndex >= column1.length + column3[0].length && "md:hidden",
              reviewIndex >= column1.length && "lg:hidden"
            )
          }
          msPerPixel={10}
        />
        <ReviewColumn
          reviews={[...column2, ...column3[1]]}
          className="hidden md:block"
          reviewClassName={reviewIndex =>
            reviewIndex >= column2.length ? "lg:hidden" : ""
          }
          msPerPixel={15}
        />
        <ReviewColumn
          reviews={column3.flat()}
          className="hidden lg:block"
          msPerPixel={10}
        />
      </>
      <div className="pointer-events-none absolute inset-x-0 top-0 h-32 bg-gradient-to-b from-gray-900" />
      <div className="pointer-events-none absolute inset-x-0 bottom-0 h-32 bg-gradient-to-t from-gray-900" />
    </div>
  )
}

export const Reviews = () => {
  return (
    <section className="relative isolate">
      <Container className="flex-col">
        <div className="mx-auto max-w-2xl lg:mx-0 mb-20">
          <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
            Wie User unsere Apps finden
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-300">
            Überzeugen Sie sich von der Qualität unserer Arbeit anhand der
            Bewertungen unserer Apps im Apple App Store und in Google Play.
          </p>
        </div>
        <ReviewGrid />
      </Container>
    </section>
  )
}
