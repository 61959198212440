import { Link } from "gatsby"
import React from "react"

import SEO from "../components/seo"
import Hero from "../components/hero"
import { Reviews } from "../components/reviews"
import Pbcp from "../components/pbcp"
import Footer from "../components/footer"

const IndexPage = () => (
  <section className="w-screen min-h-screen flex flex-col text-white bg-slate-900">
    <SEO title="Lass uns Apps bauen" />
    <Hero />
    <Reviews />
    <Pbcp />
    <Footer />
  </section>
)

export default IndexPage
