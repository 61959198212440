import clsx from "clsx"
import React from "react"

const Container = ({ children, className }) => {
  return (
    <section
      className={clsx(
        "mx-auto max-w-7xl px-6 pb-24 pt-10 sm:pb-32 lg:flex lg:px-8",
        className
      )}
    >
      {children}
    </section>
  )
}

export default Container
